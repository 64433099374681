//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
import { useAppSelector } from "../../store/hooks";
import { IDragOptions } from "../../models/current.models";
import { EMedia } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import VTCreatorDropContainer, { DropPoint } from "../components/VTCreatorDropContainer";
import {
    Box,
    CircularProgress,
    Grid,
    Slider,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { VTButton, VTSidePanel } from "@virtus-tech-repository/virtus-tech-repository";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { IScenario, IScene } from "../../models/scenario.models";
import { useEffect, useState } from "react";
import { IHotspot } from "../../models/hotspot.models";
import { setCurrentDragging, setCurrentSidePanel } from "../../store/slices/current.slice";
import { ArrowForwardRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { Params, useNavigate, useParams } from "react-router";
import VTCreatorTimeline from "../components/VTCreatorTimeline";
import * as animationData from "../../assets/icons/vh_logo.json";
import { ArrowBackIosRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import { AddRounded, CancelRounded, SaveRounded, DeleteRounded } from "@mui/icons-material";
import VTCreatorReorderDropContainer from "../components/VTCreatorReorderDropContainer";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import useMediaQuery from "@mui/material/useMediaQuery";
import { setHotspotActions } from "../../store/slices/tracking.slice";
import VTStepper from "../components/VTStepper";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    scenario: IScenario | undefined;
    scenarioLoading: boolean;
    handleMediaTimelineDropped: (timelineMedia: IDragOptions) => void;
    setSceneName: (sceneId: string, sceneName: string) => void;
    setSceneDelete: (sceneId: string) => void;
    setSceneDuplicate: (sceneId: string) => void;
    setSceneInitialView: (sceneId: string, newPosition: any) => void;
    setSceneStepper: (sceneId: string, value: boolean) => void;
    setSceneReorder: (index: number) => void;
    isLoading: boolean;
    bottomDrawWidth: number;
    setBottomDrawWidth: (bottomDrawWidth: number) => void;
    timelineMode: "Stepper";
    setTimelineMode: (timelineMode: "Stepper") => void;
    mediaType: any;
    videoNextQuestion: IHotspot | undefined;
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
    setVideoCurrentState: (videoCurrentState: "Paused" | "Playing" | "Question" | undefined) => void;
    currentQuestions: IHotspot[];
    videoCurrentTime: number;
    video: any;
    getNextQuestion: (
        time: number,
        hotspots: IHotspot[],
        setVideoNextQuestion: (nextQuestion: IHotspot | undefined) => void,
    ) => void;
    setVideoNextQuestion: (videoNextQuestion: IHotspot | undefined) => void;
    currentInterface: IHotspot | undefined;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Panel for the creator timeline
export default function VTCreatorTimelineContainer({
    scenario,
    scenarioLoading,
    handleMediaTimelineDropped,
    setSceneName,
    setSceneDelete,
    setSceneDuplicate,
    setSceneInitialView,
    setSceneStepper,
    setSceneReorder,
    isLoading,
    bottomDrawWidth,
    setBottomDrawWidth,
    timelineMode,
    setTimelineMode,
    mediaType,
    videoNextQuestion,
    setVideoCurrentState,
    videoCurrentState,
    currentQuestions,
    videoCurrentTime,
    video,
    getNextQuestion,
    setVideoNextQuestion,
    currentInterface,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stepperArray, setStepperArray] = useState<IScene[]>([]);
    const matches = useMediaQuery("(min-width:600px)");

    const [stepperOpen, setStepperOpen] = useState<boolean>(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (scenario && scenario.scenes) {
            let newStepperArray: IScene[] = [];

            scenario?.scenes.map((scene: IScene) => scene.stepper === true && newStepperArray.push(scene));

            setStepperArray(newStepperArray);
            if (newStepperArray.length > 0) {
                setBottomDrawWidth(250);
            }
        }
    }, [scenario]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Function to get src and size images based on size
    function textCutOffAddElipsis(text: string, cutOffPointCharIndex: number) {
        const charAtIndex = text.charAt(cutOffPointCharIndex);
        if (charAtIndex === " ") {
            return text.substring(0, cutOffPointCharIndex) + "...";
        } else if (charAtIndex === "") {
            return text;
        } else {
            return text.substring(0, cutOffPointCharIndex + 1) + "...";
        }
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Render the timeline with a panel
    return (
        <>
            {/* {!stepperOpen && stepperArray && stepperArray.length > 0 && (
                <VTButton
                    type={"icon"}
                    sx={{
                        position: "absolute",
                        bottom: mediaType === EMedia.VIDEO || mediaType === EMedia.VIDEO_360 ? "150px" : "80px",
                        paddingLeft: "20px",
                        backgroundColor: "rgba(100,100,100,1)",
                        cursor: "pointer",
                        ":hover": {
                            backgroundColor: "rgba(90,90,90,1)",
                        },
                        zIndex: 2000,
                        left: "-20px",
                    }}
                    onClick={() => setStepperOpen(!stepperOpen)}
                >
                    <KeyboardArrowRightIcon />
                </VTButton>
            )}

            {stepperArray && stepperArray.length > 0 && (
                <VTButton
                    type={"icon"}
                    sx={{
                        position: "absolute",

                        bottom: mediaType === EMedia.VIDEO || mediaType === EMedia.VIDEO_360 ? "240px" : "170px",
                        left: !stepperOpen ? "-100%" : "calc(48% - " + (currentInterface ? 200 : 0) + "px)",
                        transition: "0.95s",
                        height: "25px",
                        width: "80px",
                        backgroundColor: "rgba(10,10,10,0.6)",
                        borderRadius: "20px 20px 0px 0px",
                        cursor: "pointer",
                        ":hover": {
                            backgroundColor: "rgba(10,10,10,0.8)",
                        },
                        zIndex: 2000,
                    }}
                    onClick={() => setStepperOpen(!stepperOpen)}
                >
                    <KeyboardArrowLeftIcon />
                </VTButton>
            )} */}

            {stepperArray && stepperArray.length > 0 && (
                <>
                    {/* <Grid
                        item
                        container
                        xs={12}
                        sx={{
                            position: "absolute",
                            bottom: mediaType === EMedia.VIDEO || mediaType === EMedia.VIDEO_360 ? "90px" : "20px",
                            width: "calc(100% - " + (currentInterface ? 400 : 0) + "px)",
                            left: !stepperOpen ? "-100%" : 0,
                            transition: "1s",
                        }}
                        justifyContent={"center"}
                    > */}
                    {timelineMode === "Stepper" && <VTStepper stepperArray={stepperArray} mediaType={mediaType} />}
                    {/* </Grid> */}
                </>
            )}
        </>
    );
}
