//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { SetStateAction, useEffect, useState } from "react";

// Material ui Imports
import { Grid } from "@mui/material";
// Custom components imports (and hooks and helpers)
import {
    useGetAllScenarioOverviewQuery,
    useGetScenarioQuery,
    useUpdateScenarioMutation,
} from "../../services/scenario.service";
import { useGetAllGroupsQuery, useGetAllUsersQuery } from "../../services/users.service";
import {
    useGetAll360ImagesQuery,
    useGetAll360VideosQuery,
    useGetAllAudioQuery,
    useGetAllImagesQuery,
    useGetAllVideos2dQuery,
} from "../../services/media.service";
import { useGetLanguageQuery } from "../../services/language.service";
import { Params, useNavigate, useParams } from "react-router-dom";
import CreatorTabsPanelContainer from "../containers/CreatorTabsPanelContainer";
import CreatorCanvasContainer from "../containers/CreatorCanvasContainer";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    setCurrentAttachedHotspot,
    setCurrentAudio,
    setCurrentDragging,
    setCurrentHotspot,
    setCurrentLookAt,
    setCurrentQuestion,
    setCurrentScene,
    setCurrentSidePanel,
} from "../../store/slices/current.slice";

import { IDragOptions } from "../../models/current.models";
import { EMedia } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { blankScene } from "../../helpers/scenarioDataGenerator";
import { v4 as uuid } from "uuid";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { IScenario, IScene, IStep } from "../../models/scenario.models";
import { EHotspotType, IHotspot } from "../../models/hotspot.models";
import { Vector3 } from "three";
import {
    VTButton,
    VTCanvas,
    VTDialog,
    VTTextField,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";
import VTHotpsot from "../components/VTHotspot";
import CreatorFeedbackContainer from "../containers/CreatorFeedbackContainer";
import { idText } from "typescript";
import { useUpdateTrackingDataMutation } from "../../services/tracking.service";
import { clearHotspotActions, setAttempt, setHotspotActions, setSceneActions } from "../../store/slices/tracking.slice";
import { ICameraTracking, ITracking, ITrackingHotspotAction } from "../../models/tracking.models";
import ReactGA from "react-ga4";

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function CreatorContentWrapper() {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const { id: userId } = useAppSelector((state) => state.userReducer);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (scenarioId) {
            ReactGA.send({ hitType: "pageview", page: `/viewer/${scenarioId}`, title: sceneId });
        }
    }, [scenarioId]);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { data, isLoading, error } = useGetAllScenarioOverviewQuery(userId);

    const {
        data: scenario,
        isLoading: scenarioDataLoading,
        error: scenarioDataError,
    } = useGetScenarioQuery(scenarioId ? scenarioId : "");

    const [updateScenario, { isLoading: scenarioLoading }] = useUpdateScenarioMutation();
    const { currentHotspot, currentQuestion, currentSidePanel, currentlyDragging, currentScene } = useAppSelector(
        (state) => state.currentReducer,
    );
    const { attempt, sceneActions, hotspotActions } = useAppSelector((state) => state.trackingReducer);

    const [currentAttachedObject, setCurrentAttachedObject] = useState<any>(undefined);
    const [changeSceneBuffer, setChangeSceneBuffer] = useState<string | undefined>(undefined);
    const [videoCurrentState, setVideoCurrentState] = useState<"Paused" | "Playing" | "Question" | undefined>(
        undefined,
    );

    const [updateTrackingData] = useUpdateTrackingDataMutation();

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    useEffect(() => {
        if (hotspotActions !== ([] as ITrackingHotspotAction[])) {
            sendTrackingData();
        }
    }, [hotspotActions]);

    function sendTrackingData() {
        updateTrackingData({
            scenarioId: scenarioId,
            userId: userId,
            attemptId: attempt.attemptId,
            body: {
                attemptId: attempt.attemptId,
                date: attempt.date,
                actions: attempt.actions
                    ? [
                          ...attempt.actions,
                          {
                              sceneId: sceneActions.sceneId,
                              date: sceneActions.date,
                              hotspotActions: hotspotActions,
                          },
                      ]
                    : [
                          {
                              sceneId: sceneActions.sceneId,
                              date: sceneActions.date,
                              hotspotActions: hotspotActions,
                          },
                      ],
                camera: [],
            },
        });
    }

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (scenario && scenario.scenes.length === 0) {
            handleMediaTimelineDropped({
                mediaType: EDragContentOptions.IMAGE_360,
                src: "https://d2zeysh26k1sla.cloudfront.net/app_defaults/default_360_image",
            });
        }

        // dispatch(setCurrentHotspot({ currentHotspot: undefined, open: false }));
        if (!sceneId && scenario && scenario.scenes.length) {
            // If no scene is provided in the url load first scene in the array
            navigate(`/viewer/${scenarioId}/${scenario.scenes[0].id}`);

            dispatch(setCurrentScene(scenario.scenes[0]));
        } else if (scenario) {
            // If sceneId was provided find scene and set current scene
            const foundScene: IScene | undefined = scenario?.scenes.find((scene: IScene) => scene.id === sceneId);

            if (foundScene) {
                if (sceneActions.sceneId) {
                    dispatch(
                        setAttempt({
                            attemptId: attempt.attemptId,
                            date: attempt.date,
                            actions: [
                                ...attempt.actions,
                                {
                                    sceneId: sceneActions.sceneId,
                                    date: sceneActions.date,
                                    hotspotActions: hotspotActions,
                                },
                            ],
                            camera: [],
                        }),
                    );
                }

                dispatch(setSceneActions({ sceneId: foundScene.id, date: new Date(), hotspotActions: [] }));
                dispatch(clearHotspotActions());
                navigate(`/viewer/${scenarioId}/${foundScene.id}`);
                dispatch(setCurrentScene(foundScene));
            } else {
                // If the sceneId could no be found load first scene
                if (scenario.scenes && scenario.scenes[0] && scenario.scenes[0].id) {
                    if (sceneActions.sceneId) {
                        dispatch(
                            setAttempt({
                                attemptId: attempt.attemptId,
                                date: attempt.date,
                                actions: [
                                    ...attempt.actions,
                                    {
                                        sceneId: sceneActions.sceneId,
                                        date: sceneActions.date,
                                        hotspotActions: hotspotActions,
                                    },
                                ],
                                camera: [],
                            }),
                        );
                    }
                    dispatch(setSceneActions({ sceneId: scenario.scenes[0].id, date: new Date(), hotspotActions: [] }));
                    dispatch(clearHotspotActions());
                    navigate(`/viewer/${scenarioId}/${scenario.scenes[0].id}`);
                    dispatch(setCurrentScene(scenario.scenes[0]));
                }
            }
        }
        if (scenario && changeSceneBuffer && scenario.scenes.find((scene: IScene) => scene.id === changeSceneBuffer)) {
            navigate(`/viewer/${scenarioId}/${changeSceneBuffer}`);
            setChangeSceneBuffer(undefined);
        }
    }, [sceneId, scenario]);

    useEffect(() => {
        dispatch(setCurrentHotspot({ currentHotspot: undefined, open: false }));

        if (scenario) {
            const foundScene: IScene | undefined = scenario?.scenes.find((scene: IScene) => scene.id === sceneId);

            foundScene && foundScene.audio && dispatch(setCurrentAudio(foundScene.audio));
        }
    }, [sceneId]);

    useEffect(() => {
        dispatch(setAttempt({ attemptId: uuid(), date: new Date(), actions: [], camera: [] }));
        dispatch(
            setCurrentSidePanel({
                currentTab: undefined,
                width: 0,
                option: undefined,
            }),
        );
    }, []);

    useEffect(() => {
        if (scenarioDataError) {
            console.error("error getting scenario: ", scenarioId, scenarioDataError);
        }
    }, [scenarioDataError]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Function to handle media dragged from the side panel onto the timeline
    function handleMediaTimelineDropped(timelineMedia: IDragOptions) {
        // TODO; Add new scene
        const newSceneId = uuid();

        if (scenario && timelineMedia.mediaType === EDragContentOptions.VIDEO_360) {
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes,
                    blankScene(timelineMedia.src, EMedia.VIDEO_360, timelineMedia.preview, newSceneId),
                ],
            };

            updateScenario(newScenario);
        } else if (scenario && timelineMedia.mediaType === EDragContentOptions.IMAGE_360) {
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes,
                    blankScene(timelineMedia.src, EMedia.IMAGE_360, timelineMedia.preview, newSceneId),
                ],
            };

            updateScenario(newScenario);
        } else if (scenario && timelineMedia.mediaType === EDragContentOptions.VIDEO) {
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes,
                    blankScene(timelineMedia.src, EMedia.VIDEO, timelineMedia.preview, newSceneId),
                ],
            };

            updateScenario(newScenario);
        }
        setChangeSceneBuffer(newSceneId);
    }

    // Function to handle media dragged from the side panel onto the canvas
    function handleMediaCanvasDropped(canvasMedia: IDragOptions) {
        if (canvasMedia.mediaType === EDragContentOptions.IMAGE_360) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: scenario.scenes.map((element: IScene) =>
                        currentScene.id === element.id
                            ? {
                                  ...element,
                                  src: canvasMedia.src,
                                  mediaType: EMedia.IMAGE_360,
                                  hotspots: currentScene.hotspots.filter(
                                      (hotspot: IHotspot) => hotspot.type !== EHotspotType.QUESTION,
                                  ),

                                  previewImage: canvasMedia.preview,
                              }
                            : element,
                    ),
                };

                updateScenario(newScenario);
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.VIDEO_360) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: scenario.scenes.map((element: IScene) =>
                        currentScene.id === element.id
                            ? {
                                  ...element,
                                  src: canvasMedia.src,
                                  mediaType: EMedia.VIDEO_360,
                                  hotspots: currentScene.hotspots.filter(
                                      (hotspot: IHotspot) => hotspot.type !== EHotspotType.QUESTION,
                                  ),

                                  previewImage: canvasMedia.preview,
                              }
                            : element,
                    ),
                };

                updateScenario(newScenario);
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.AUDIO) {
            if (scenario) {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      audio: canvasMedia.src,
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
            }
        } else if (
            canvasMedia.mediaType === EDragContentOptions.INFORMATION ||
            canvasMedia.mediaType === EDragContentOptions.MOVEMENT ||
            canvasMedia.mediaType === EDragContentOptions.IMAGE ||
            canvasMedia.mediaType === EDragContentOptions.VIDEO ||
            canvasMedia.mediaType === EDragContentOptions.ANSWER ||
            canvasMedia.mediaType === EDragContentOptions.INTERFACE
        ) {
            if (scenario) {
            }
        }
    }

    function handleMediaQuestionCanvasDropped(canvasMedia: IDragOptions, hotspotId?: string) {
        if (canvasMedia.mediaType === EDragContentOptions.IMAGE_360) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.filter((element: any) => currentScene.id !== element.id),
                        {
                            id: currentScene.id,
                            name: currentScene.name,
                            src: canvasMedia.src,
                            mediaType: EMedia.IMAGE_360,
                            hotspots: currentScene.hotspots.filter(
                                (hotspot: IHotspot) => hotspot.type !== EHotspotType.QUESTION,
                            ),
                            context: {},
                            startLocation: [0, 0, 0],
                            previewImage: canvasMedia.preview,
                        },
                    ],
                };

                updateScenario(newScenario);
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.VIDEO_360) {
            if (scenario) {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.filter((element: any) => currentScene.id !== element.id),
                        {
                            id: currentScene.id,
                            name: currentScene.name,
                            src: canvasMedia.src,
                            mediaType: EMedia.VIDEO_360,
                            hotspots: currentScene.hotspots.filter(
                                (hotspot: IHotspot) => hotspot.type !== EHotspotType.QUESTION,
                            ),
                            context: {},
                            startLocation: [0, 0, 0],
                            previewImage: canvasMedia.preview,
                        },
                    ],
                };

                updateScenario(newScenario);
            }
        } else if (canvasMedia.mediaType === EDragContentOptions.AUDIO) {
            if (scenario) {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [
                                          ...scene.hotspots.map((hotspot: IHotspot) => {
                                              if (hotspot.id === hotspotId) {
                                                  return {
                                                      ...hotspot,
                                                      contents: {
                                                          preClickContent: {
                                                              ...hotspot.contents.preClickContent,
                                                              question: {
                                                                  ...hotspot.contents.preClickContent.question,
                                                                  audio: canvasMedia.src,
                                                              },
                                                          },
                                                      },
                                                  };
                                              } else {
                                                  return hotspot;
                                              }
                                          }),
                                      ],
                                  }
                                : scene,
                        ),
                    ],
                };
                //@ts-ignore
                updateScenario(newScenario);
            }
        } else {
            if (scenario) {
            }
        }
    }

    // Function to handle media dragged from the side panel onto the canvas
    function handleSceneDelete(sceneId: string) {
        if (scenario) {
            const newScenario: IScenario = {
                ...scenario,
                scenes: scenario.scenes.filter((element: IScene) => element.id !== sceneId),
            };

            updateScenario(newScenario);
        }
    }

    // Function to handle media dragged from the side panel onto the canvas
    function handleAudioDelete(sceneId: string, questionId?: string) {
        if (scenario) {
            if (questionId) {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [
                                          ...scene.hotspots.map((hotspot: IHotspot) => {
                                              if (hotspot.id === questionId) {
                                                  return {
                                                      ...hotspot,
                                                      contents: {
                                                          preClickContent: {
                                                              ...hotspot.contents.preClickContent,
                                                              question: {
                                                                  ...hotspot.contents.preClickContent.question,
                                                                  audio: undefined,
                                                              },
                                                          },
                                                      },
                                                  };
                                              } else {
                                                  return hotspot;
                                              }
                                          }),
                                      ],
                                  }
                                : scene,
                        ),
                    ],
                };
                dispatch(setCurrentAudio(""));
                //@ts-ignore
                updateScenario(newScenario);
            } else {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      audio: undefined,
                                  }
                                : scene,
                        ),
                    ],
                };

                dispatch(setCurrentAudio(""));
                updateScenario(newScenario);
            }
        }
    }

    function handleSceneDuplicate(sceneId: string) {
        if (scenario) {
            const sceneToDuplicate = scenario.scenes.find((element: IScene) => element.id === sceneId);
            const newSceneId = uuid();
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes,
                    {
                        id: newSceneId,
                        name: sceneToDuplicate!.name + " - copy",
                        src: sceneToDuplicate!.src,
                        mediaType: sceneToDuplicate!.mediaType,
                        hotspots: sceneToDuplicate!.hotspots,
                        context: sceneToDuplicate!.context,
                        startLocation: sceneToDuplicate!.startLocation,
                        previewImage: sceneToDuplicate!.previewImage,
                        audio: sceneToDuplicate!.audio,
                    },
                ],
            };

            updateScenario(newScenario);
            setChangeSceneBuffer(newSceneId);
        }
    }

    function handleSceneNameUpdate(sceneId: string, sceneName: string) {
        if (scenario) {
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) =>
                        scene.id === sceneId ? { ...scene, name: sceneName } : scene,
                    ),
                ],
            };

            updateScenario(newScenario);
        }
    }

    function handleSceneSetInitialView(sceneId: string, newPosition: any) {
        if (scenario) {
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) =>
                        scene.id === sceneId
                            ? {
                                  ...scene,
                                  startLocation: [
                                      newPosition.x.toFixed(5),
                                      newPosition.y.toFixed(5),
                                      newPosition.z.toFixed(5),
                                  ],
                              }
                            : scene,
                    ),
                ],
            };

            updateScenario(newScenario);
        }
    }

    function handleAddSceneToStepper(sceneId: string, value: boolean) {
        if (scenario) {
            const sceneToDuplicate = scenario.scenes.find((element: IScene) => element.id === sceneId);
            const newScenario: IScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) =>
                        scene.id === sceneId ? { ...scene, stepper: value } : scene,
                    ),
                ],
            };

            updateScenario(newScenario);
        }
    }

    function handleSceneReorder(sceneNewIndex: number) {
        if (scenario) {
            const sceneToDuplicateChange = scenario.scenes.find(
                (element: IScene) => element.id === currentlyDragging?.src,
            );
            const sceneToDuplicateIndex = scenario.scenes.findIndex(
                (element: IScene) => element.id === currentlyDragging?.src,
            );
            let newSceneList: IScene[] = [];

            scenario.scenes.map((scene: IScene, index: number) => {
                if (index === sceneNewIndex) {
                    newSceneList.push(sceneToDuplicateChange!);
                    newSceneList.push(scene);
                } else {
                    newSceneList.push(scene);
                }
            });
            if (scenario.scenes.length === sceneNewIndex) {
                newSceneList.push(sceneToDuplicateChange!);
            }

            if (sceneToDuplicateIndex > sceneNewIndex) {
                newSceneList.splice(sceneToDuplicateIndex + 1, 1);
            } else {
                newSceneList.splice(sceneToDuplicateIndex, 1);
            }

            const newScenario: IScenario = {
                ...scenario,
                scenes: newSceneList,
            };

            dispatch(setCurrentDragging(undefined));
            updateScenario(newScenario);
        }
    }

    function handleDeleteStopPoint(sceneId: string, hotspotId: string) {
        if (scenario) {
            const newScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) =>
                        scene.id === sceneId
                            ? {
                                  ...scene,
                                  hotspots: scene.hotspots.filter((hotspot: IHotspot) => hotspot.id !== hotspotId),
                              }
                            : scene,
                    ),
                ],
            };

            //@ts-ignore
            updateScenario(newScenario);
        }
    }

    function handleSaveHotspotLocation(hotspotId: string, newPosition: Vector3, videoCurrentState: string | undefined) {
        if (scenario && scenario.scenes) {
            if (videoCurrentState === "Question") {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [
                                          ...scene.hotspots.map((hotspot: IHotspot) =>
                                              hotspot.id === currentQuestion.id
                                                  ? {
                                                        ...hotspot,
                                                        contents: {
                                                            ...hotspot.contents,
                                                            preClickContent: {
                                                                ...hotspot.contents.preClickContent,
                                                                question: {
                                                                    ...hotspot.contents.preClickContent.question!,
                                                                    answers:
                                                                        hotspot.contents.preClickContent.question!.answers.map(
                                                                            (answer: IHotspot) =>
                                                                                answer.id === hotspotId
                                                                                    ? {
                                                                                          ...answer,
                                                                                          location: newPosition,
                                                                                      }
                                                                                    : answer,
                                                                        ),
                                                                },
                                                            },
                                                        },
                                                    }
                                                  : hotspot,
                                          ),
                                      ],
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
                setCurrentAttachedObject(undefined);
            } else {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: scene.hotspots.map((hotspot: IHotspot) =>
                                          hotspot.id === hotspotId
                                              ? {
                                                    ...hotspot,
                                                    location: newPosition,
                                                }
                                              : hotspot,
                                      ),
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
                setCurrentAttachedObject(undefined);
            }
        }
    }

    function handleDeleteHotspotLocation(hotspotId: string) {
        if (scenario) {
            if (
                scenario.scenes
                    .find((e: IScene) => e.id === currentScene.id)
                    ?.hotspots.find((e: IHotspot) => e.id === currentQuestion.id)
                    ?.contents.preClickContent.question?.answers.find((e: IHotspot) => e.id === hotspotId)
            ) {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [
                                          ...scene.hotspots.map((hotspot: IHotspot) =>
                                              hotspot.id === currentQuestion.id
                                                  ? {
                                                        ...hotspot,
                                                        contents: {
                                                            ...hotspot.contents,
                                                            preClickContent: {
                                                                ...hotspot.contents.preClickContent,
                                                                question: {
                                                                    ...hotspot.contents.preClickContent.question!,
                                                                    answers:
                                                                        hotspot.contents.preClickContent.question!.answers.filter(
                                                                            (answer: IHotspot) =>
                                                                                answer.id !== hotspotId,
                                                                        ),
                                                                },
                                                            },
                                                        },
                                                    }
                                                  : hotspot,
                                          ),
                                      ],
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
            } else {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: scene.hotspots.filter((hotspot: IHotspot) => hotspot.id !== hotspotId),
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
            }
            if (currentHotspot && currentHotspot.currentHotspot && currentHotspot.currentHotspot.id === hotspotId) {
                dispatch(setCurrentHotspot({ currentHotspot: undefined, open: false }));
            }
        }
    }

    function handleUpdateInterface(hotspotId: string, newInterface: IHotspot) {
        if (scenario) {
            if (
                scenario.scenes
                    .find((e: IScene) => e.id === currentScene.id)
                    ?.hotspots.find((e: IHotspot) => e.id === currentQuestion.id)
                    ?.contents.preClickContent.question?.answers.find((e: IHotspot) => e.id === hotspotId)
            ) {
                const newScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: [
                                          ...scene.hotspots.map((hotspot: IHotspot) =>
                                              hotspot.id === currentQuestion.id
                                                  ? {
                                                        ...hotspot,
                                                        contents: {
                                                            ...hotspot.contents,
                                                            preClickContent: {
                                                                ...hotspot.contents.preClickContent,
                                                                question: {
                                                                    ...hotspot.contents.preClickContent.question!,
                                                                    answers:
                                                                        hotspot.contents.preClickContent.question!.answers.map(
                                                                            (answer: IHotspot) =>
                                                                                answer.id === hotspotId
                                                                                    ? newInterface
                                                                                    : answer,
                                                                        ),
                                                                },
                                                            },
                                                        },
                                                    }
                                                  : hotspot,
                                          ),
                                      ],
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
            } else {
                const newScenario: IScenario = {
                    ...scenario,
                    scenes: [
                        ...scenario.scenes.map((scene: IScene) =>
                            scene.id === sceneId
                                ? {
                                      ...scene,
                                      hotspots: scene.hotspots.map((hotspot: IHotspot) =>
                                          hotspot.id === hotspotId ? newInterface : hotspot,
                                      ),
                                  }
                                : scene,
                        ),
                    ],
                };

                updateScenario(newScenario);
            }
        }
    }

    function handleEditQuestionTitle(questionId: string, newTitle: string) {
        if (scenario) {
            const newScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) =>
                        scene.id === sceneId
                            ? {
                                  ...scene,
                                  hotspots: [
                                      ...scene.hotspots.map((hotspot: IHotspot) =>
                                          hotspot.id === questionId
                                              ? {
                                                    ...hotspot,
                                                    contents: {
                                                        ...hotspot.contents,
                                                        preClickContent: {
                                                            ...hotspot.contents.preClickContent,
                                                            title: newTitle,
                                                        },
                                                    },
                                                }
                                              : hotspot,
                                      ),
                                  ],
                              }
                            : scene,
                    ),
                ],
            };

            updateScenario(newScenario);
        }
    }

    function handleEditQuestionTimer(questionId: string, newTimer: number) {
        if (scenario) {
            const newScenario = {
                ...scenario,
                scenes: [
                    ...scenario.scenes.map((scene: IScene) =>
                        scene.id === sceneId
                            ? {
                                  ...scene,
                                  hotspots: [
                                      ...scene.hotspots.map((hotspot: IHotspot) =>
                                          hotspot.id === questionId
                                              ? {
                                                    ...hotspot,
                                                    contents: {
                                                        ...hotspot.contents,
                                                        preClickContent: {
                                                            ...hotspot.contents.preClickContent,
                                                            question: {
                                                                ...hotspot.contents.preClickContent.question,
                                                                countdown: newTimer,
                                                            },
                                                        },
                                                    },
                                                }
                                              : hotspot,
                                      ),
                                  ],
                              }
                            : scene,
                    ),
                ],
            };

            //@ts-ignore
            updateScenario(newScenario);
        }
    }
    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // call relevant data from api and get from store
    return (
        <>
            <Grid container xs={12} justifyContent={"flex-end"}>
                {/*-----------------------------------------------------------------------------------------*/}
                {/* Main page content */}
                <CreatorCanvasContainer
                    scenarioLoading={scenarioDataLoading}
                    scene={currentScene}
                    handleMediaCanvasDropped={handleMediaCanvasDropped}
                    handleMediaQuestionCanvasDropped={handleMediaQuestionCanvasDropped}
                    handleDeleteStopPoint={handleDeleteStopPoint}
                    isLoading={scenarioDataLoading || scenarioLoading || isLoading}
                    handleSaveHotspotLocation={handleSaveHotspotLocation}
                    handleDeleteHotspotLocation={handleDeleteHotspotLocation}
                    handleAudioDelete={handleAudioDelete}
                    handleEditQuestionTitle={handleEditQuestionTitle}
                    handleEditQuestionTimer={handleEditQuestionTimer}
                    scenario={scenario ? scenario : ({} as IScenario)}
                    updateScenario={updateScenario}
                    currentAttachedObject={currentAttachedObject}
                    setCurrentAttachedObject={setCurrentAttachedObject}
                    handleMediaTimelineDropped={handleMediaTimelineDropped}
                    setSceneName={handleSceneNameUpdate}
                    setSceneDelete={handleSceneDelete}
                    setSceneDuplicate={handleSceneDuplicate}
                    setSceneInitialView={handleSceneSetInitialView}
                    setSceneStepper={handleAddSceneToStepper}
                    setSceneReorder={handleSceneReorder}
                    handleUpdateInterface={handleUpdateInterface}
                    videoCurrentState={videoCurrentState}
                    setVideoCurrentState={setVideoCurrentState}
                />

                <CreatorFeedbackContainer />
            </Grid>
        </>
    );
}
