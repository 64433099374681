//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import AppRoutes from "./views/AppRoutes";
import { darkTheme } from "./themes/dark.theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { lightTheme } from "./themes/light.theme";
import { useAppSelector } from "./store/hooks";
import { SelectedTheme } from "./store/slices/theme.slice";

import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import ReactGA from "react-ga4";
import { useEffect } from "react";

// AWS Amplify imports

// Material ui Imports
// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom

//---------------------------------------------------------------------------------------------------
// ## Sentry setup for logging ##
Sentry.init({
    dsn: "https://ec253de096edddea44ad37cb9de27c71@o4506622511546368.ingest.sentry.io/4506622513250304",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", "https://viewer.digi-sim.co.uk"],
        }),
        new CaptureConsole({
            levels: ["error"],
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    maxBreadcrumbs: 50,
});

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// DigiDeck, hosting set theme ccs and routes
function DigiDeck() {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { theme } = useAppSelector((state) => state.themeReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        ReactGA.initialize([
            {
                trackingId: "G-MHKZS0E6B9",
                gaOptions: { testMode: true },
            },
        ]);
    }, []);
    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Gets the user details from the login and stores in redux
    return (
        <ThemeProvider theme={theme === SelectedTheme.DARK ? darkTheme : lightTheme}>
            <CssBaseline />
            <AppRoutes />
        </ThemeProvider>
    );
}

// Export app
export default DigiDeck;
