//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import {
    Card,
    CardHeader,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { IHotspot, IUserInterface } from "../../models/hotspot.models";
import { v4 as uuid } from "uuid";
import {
    VTButton,
    VTDialog,
    VTMenu,
    VTRichTextEditor,
    VTTextField,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";

import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect, useRef, useState } from "react";
import {
    AddRounded,
    DeleteRounded,
    SaveRounded,
    CancelRounded,
    SettingsRounded,
    Diversity1,
} from "@mui/icons-material";
import { IScene } from "../../models/scenario.models";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Params, useNavigate, useParams } from "react-router-dom";
import VTVideoDropper from "./VTVideoDropper";
import VTAudioDropper from "./VTAudioDropper";
import { useAppSelector } from "../../store/hooks";
import VTImageDropper from "./VTImageDropper";

import { idText } from "typescript";
import { IUser } from "../../models/user.model";
import { setHotspotActions } from "../../store/slices/tracking.slice";
import { useDispatch } from "react-redux";
import {
    setCurrentAudio,
    setCurrentFeedbackImage,
    setCurrentFeedbackInput,
    setCurrentFeedbackVideo,
} from "../../store/slices/current.slice";
// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    currentInterface: IHotspot;
    setCurrentInterface: (currentInteface: IHotspot | undefined) => void;
    handleDeleteHotspotLocation: (hotspotId: string) => void;
    handleUpdateInterface: (hotspotId: string, newInterface: IHotspot) => void;
    handleProceedVideo: (delayInSeconds: number | undefined) => void;
    handleChangeScene: (sceneId: string, delayInSeconds?: number) => void;
    setInterfaceCompleted: (interfaceId: string) => void;
    videoCurrentState: any;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function VTUserInterface({
    currentInterface,
    setCurrentInterface,
    handleDeleteHotspotLocation,
    handleUpdateInterface,
    handleProceedVideo,
    handleChangeScene,
    setInterfaceCompleted,
    videoCurrentState,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    const { sceneId }: Readonly<Params<string>> = useParams();

    const dispatch = useDispatch();
    const { currentSidePanel, currentQuestion } = useAppSelector((state) => state.currentReducer);
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [localUserInterface, setLocalUserInterface] = useState<IHotspot>(currentInterface);
    const [userInterfaceIndex, setUserInterfaceIndex] = useState<number>(0);
    const [nextDisabled, setNextDisabled] = useState<boolean | undefined>(undefined);
    const [localUserInterfaceSection, setLocalUserInterfaceSection] = useState<IUserInterface>(
        currentInterface.contents.preClickContent!.userInterface![userInterfaceIndex],
    );

    const [sectionMultiChoiceAnswer, setSectionMultiChoiceAnswer] = useState<{ id: string; values: string[] }[]>([]);
    const navigate = useNavigate();

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    useEffect(() => {
        setLocalUserInterfaceSection(
            //@ts-ignore
            localUserInterface.contents.preClickContent.userInterface[userInterfaceIndex]!,
        );
        if (
            localUserInterface.contents.preClickContent.userInterface![userInterfaceIndex]!.sectionContent &&
            localUserInterface.contents.preClickContent.userInterface![userInterfaceIndex]!.sectionContent!.filter(
                (element: { id: string; field: string; value: string }) => element.field === "Model Answer",
            ).length > 0
        ) {
            setNextDisabled(true);
        }
    }, [userInterfaceIndex]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Add new Section to interface
    function handlesAddSectionToLocalInterface() {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    userInterface: [
                        ...localUserInterface.contents.preClickContent.userInterface!,
                        {
                            sectionTitle: `New Section ${localUserInterface.contents.preClickContent.userInterface
                                ?.length!}`,
                        },
                    ],
                },
            },
        });
    }

    // Delete Section from interface
    function handlesDeleteSectionToLocalInterface() {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    userInterface: localUserInterface.contents.preClickContent.userInterface!.filter(
                        (section: IUserInterface, index: number) => index !== userInterfaceIndex,
                    ),
                },
            },
        });
    }

    // Update interfaces overall title
    function handleUpdateInterfaceTitle(newTitle: string) {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    title: newTitle,
                },
            },
        });
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <Grid
            container
            sx={{
                position: "absolute",
                right: 0,
                width: "400px",
                height: "100%",
                backgroundColor: "#3a3b3d",
                zIndex: 10,
                padding: "15px",
            }}
            justifyContent={"center"}
            alignContent={"space-between"}
        >
            {/*------------------------------------------------------------------------------------*/}
            {/* Interface Title Section */}
            {/*------------------------------------------------------------------------------------*/}
            {/* Interface Title */}
            <Grid item container justifyContent={"center"} alignContent={"center"} sx={{ marginTop: "20px" }}>
                {/*--------------------------------------------------------------------------------*/}
                {/* If editing interface title show text field and data management options */}
                <VTTypography>{localUserInterface.contents.preClickContent.title}</VTTypography>
            </Grid>

            {/*----------------------------------------------------------------------------------------*/}
            {/* Interface Timeline section */}
            {/*----------------------------------------------------------------------------------------*/}

            <Grid
                container
                sx={{
                    marginTop: "-20px",
                    height: "100px",
                    padding: "30px",
                    width: "100%",
                    overflowX: "scroll",
                    overflowY: "hidden",
                }}
                direction={"column"}
                alignContent={
                    localUserInterface.contents.preClickContent.userInterface!.length < 4 ? "center" : "normal"
                }
            >
                {localUserInterface.contents.preClickContent.userInterface?.map(
                    (element: IUserInterface, index: number) => {
                        return (
                            <>
                                <VTButton
                                    type={"icon"}
                                    sx={{
                                        width: "40px",
                                        height: "40px",
                                        background: "linear-gradient(to bottom, #0ab5a7,#8e25fa)",
                                        transition: ".5s",
                                        boxShadow: userInterfaceIndex === index ? "0 0 25px #fff" : "none",
                                        ":hover": {
                                            boxShadow: "0 0 25px #fff",
                                        },
                                    }}
                                >
                                    {index}
                                </VTButton>
                                {localUserInterface.contents.preClickContent.userInterface!.length !== index + 1 && (
                                    <hr
                                        style={{
                                            color: "#fff",
                                            backgroundColor: "#fff",
                                            height: "3px",
                                            width: "40px",
                                            borderRadius: "40%",
                                            marginTop: "18.5px",
                                        }}
                                    />
                                )}
                            </>
                        );
                    },
                )}
            </Grid>

            {/*----------------------------------------------------------------------------------------*/}
            {/* Section Title area */}
            {/*----------------------------------------------------------------------------------------*/}
            <Grid container item xs={12} sx={{ height: "calc(100% - 200px)", overflowY: "auto" }}>
                <Grid item container xs={12}>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* If editing section title */}

                    <VTTypography>{localUserInterfaceSection.sectionTitle}</VTTypography>
                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    sx={{
                        height: "calc(100% - 100px)",
                        marginTop: "-20px",
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}
                    justifyContent={"center"}
                >
                    {localUserInterfaceSection.sectionContent &&
                        localUserInterfaceSection.sectionContent!.map(
                            (element: { id: string; field: string; value: string }, index: number) => {
                                if (element.field === "Question") {
                                    return (
                                        <Grid item container xs={12}>
                                            <VTTypography>
                                                {localUserInterfaceSection.sectionContent![index].value}
                                            </VTTypography>
                                        </Grid>
                                    );
                                } else if (element.field === "Model Answer") {
                                    return (
                                        <Grid item container xs={12}>
                                            {!nextDisabled && (
                                                <VTTypography>
                                                    {localUserInterfaceSection.sectionContent![index].value}
                                                </VTTypography>
                                            )}
                                        </Grid>
                                    );
                                } else if (element.field === "User Text Input") {
                                    let fieldValue = "";
                                    return (
                                        <Grid item container xs={12} sx={{ width: "100%" }}>
                                            <VTTextField
                                                sx={{ width: "100%" }}
                                                multiline
                                                rows={3}
                                                value={localUserInterfaceSection.sectionContent![index].value}
                                                disabled={nextDisabled === false}
                                                onChange={(e) =>
                                                    setLocalUserInterfaceSection({
                                                        ...localUserInterfaceSection,
                                                        sectionContent: [
                                                            ...localUserInterfaceSection.sectionContent!.map(
                                                                (interfaceElement: {
                                                                    id: string;
                                                                    field: string;
                                                                    value: string;
                                                                }) => {
                                                                    if (interfaceElement.id === element.id) {
                                                                        return { ...interfaceElement, value: e };
                                                                    } else {
                                                                        return interfaceElement;
                                                                    }
                                                                },
                                                            ),
                                                        ],
                                                    })
                                                }
                                            />
                                        </Grid>
                                    );
                                } else {
                                    const splitArray: string[] = [
                                        ...element.value.split(";¬;").filter((element: string) => element !== ""),
                                    ];
                                    const numOfCorrect: number = splitArray.filter((correctElement: string) =>
                                        correctElement.includes("%^%"),
                                    ).length;

                                    const numOfUserCorrect: number = splitArray.filter((correctElement: string) =>
                                        correctElement.includes("£*£"),
                                    ).length;

                                    return (
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            sx={{
                                                height: "250px",
                                                width: "100%",
                                                zIndex: 1000,
                                                overflowY: "hidden",
                                                position: "relative",
                                            }}
                                        >
                                            <Card>
                                                <CardHeader
                                                    sx={{ px: 12, py: 1 }}
                                                    subheader={`${numOfCorrect}/${splitArray.length} selected answers`}
                                                />

                                                <Divider />
                                                <List
                                                    sx={{
                                                        width: "400px",
                                                        height: "250px",
                                                        bgcolor: "background.paper",
                                                        overflow: "auto",
                                                        paddingBottom: "100px",
                                                    }}
                                                    dense
                                                    component="div"
                                                    role="list"
                                                >
                                                    {splitArray.map((value: string, index: number) => {
                                                        const labelId = `transfer-list-all-item-${value}-label`;
                                                        const valueArray = value.split("£*£");

                                                        return (
                                                            <ListItemButton
                                                                key={value}
                                                                role="listitem"
                                                                sx={
                                                                    nextDisabled === false
                                                                        ? value.includes("£*£") && value.includes("%^%")
                                                                            ? {
                                                                                  backgroundColor: "#007700",
                                                                                  ":hover": {
                                                                                      backgroundColor: "#005500",
                                                                                  },
                                                                              }
                                                                            : !value.includes("£*£") &&
                                                                              !value.includes("%^%")
                                                                            ? {
                                                                                  backgroundColor: "#007700",
                                                                                  ":hover": {
                                                                                      backgroundColor: "#005500",
                                                                                  },
                                                                              }
                                                                            : {
                                                                                  backgroundColor: "#770000",
                                                                                  ":hover": {
                                                                                      backgroundColor: "#550000",
                                                                                  },
                                                                              }
                                                                        : {}
                                                                }
                                                                onClick={() => {
                                                                    if (nextDisabled !== false) {
                                                                        if (valueArray.length > 1) {
                                                                            const temp = splitArray.map(
                                                                                (
                                                                                    subElement: string,
                                                                                    subIndex: number,
                                                                                ) =>
                                                                                    subIndex === index
                                                                                        ? subElement.split("£*£")[0]
                                                                                        : subElement,
                                                                            );
                                                                            setLocalUserInterfaceSection({
                                                                                ...localUserInterfaceSection,
                                                                                sectionContent: [
                                                                                    ...localUserInterfaceSection.sectionContent!.map(
                                                                                        (checkedElement: {
                                                                                            id: string;
                                                                                            field: string;
                                                                                            value: string;
                                                                                        }) =>
                                                                                            checkedElement.id ===
                                                                                            element.id
                                                                                                ? {
                                                                                                      ...checkedElement,
                                                                                                      value: temp
                                                                                                          .toString()
                                                                                                          .replace(
                                                                                                              /,/g,
                                                                                                              ";¬;",
                                                                                                          ),
                                                                                                  }
                                                                                                : checkedElement,
                                                                                    ),
                                                                                ],
                                                                            });
                                                                        } else {
                                                                            const temp = splitArray.map(
                                                                                (
                                                                                    subElement: string,
                                                                                    subIndex: number,
                                                                                ) =>
                                                                                    subIndex === index
                                                                                        ? subElement + "£*£"
                                                                                        : subElement,
                                                                            );
                                                                            setLocalUserInterfaceSection({
                                                                                ...localUserInterfaceSection,
                                                                                sectionContent: [
                                                                                    ...localUserInterfaceSection.sectionContent!.map(
                                                                                        (checkedElement: {
                                                                                            id: string;
                                                                                            field: string;
                                                                                            value: string;
                                                                                        }) =>
                                                                                            checkedElement.id ===
                                                                                            element.id
                                                                                                ? {
                                                                                                      ...checkedElement,
                                                                                                      value: temp
                                                                                                          .toString()
                                                                                                          .replace(
                                                                                                              /,/g,
                                                                                                              ";¬;",
                                                                                                          ),
                                                                                                  }
                                                                                                : checkedElement,
                                                                                    ),
                                                                                ],
                                                                            });
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        checked={valueArray.length > 1}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{
                                                                            "aria-labelledby": labelId,
                                                                        }}
                                                                        sx={{ color: "white" }}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    id={labelId}
                                                                    primary={valueArray[0].split("%^%")[0]}
                                                                />
                                                            </ListItemButton>
                                                        );
                                                    })}
                                                </List>
                                            </Card>
                                        </Grid>
                                    );
                                }
                            },
                        )}
                </Grid>
            </Grid>

            {/*------------------------------------------------------------------------------------------*/}
            {/* Closes the interface panel */}
            {/* <VTButton
                sx={{
                    width: "10px",
                    height: "40px",
                    borderRadius: "15px 0px 0px 15px",
                    backgroundColor: "#a9a9a9",
                    ":hover": {
                        cursor: "pointer",
                        backgroundColor: "#888888",
                        boxShadow: "0 0 25px #2ad5c7",
                    },
                    marginBlock: "15px",
                    position: "absolute",
                    left: "-64px",
                    top: "42%",
                }}
                onClick={() => setCurrentInterface(undefined)}
            >
                <LogoutIcon />
            </VTButton> */}

            <Grid item container xs={12} justifyContent={"space-around"} sx={{ marginTop: "-35px" }}>
                {/*------------------------------------------------------------------------------------------*/}
                {/* sets data on the server */}
                <Grid item justifyContent={"flex-end"} container>
                    <VTButton
                        sx={{ width: "150px" }}
                        onClick={() => {
                            if (nextDisabled === true) {
                                setNextDisabled(false);
                            } else {
                                localUserInterfaceSection.sectionContent &&
                                    localUserInterfaceSection.sectionContent!.forEach(
                                        (element: { id: string; field: string; value: string }, index: number) => {
                                            if (element.field === "User Text Input") {
                                                if (videoCurrentState === "Question") {
                                                    dispatch(
                                                        setHotspotActions({
                                                            hotspotId: currentInterface.id,
                                                            action: "UI Text Input",
                                                            date: new Date(),
                                                            questionId: currentQuestion.id,
                                                            data: element.value,
                                                        }),
                                                    );
                                                } else {
                                                    dispatch(
                                                        setHotspotActions({
                                                            hotspotId: currentInterface.id,
                                                            action: "UI Text Input",
                                                            date: new Date(),
                                                            data: element.value,
                                                        }),
                                                    );
                                                }
                                            } else {
                                                if (videoCurrentState === "Question") {
                                                    dispatch(
                                                        setHotspotActions({
                                                            hotspotId: currentInterface.id,
                                                            action: "UI Multi choice",
                                                            date: new Date(),
                                                            questionId: currentQuestion.id,
                                                            data: element.value,
                                                        }),
                                                    );
                                                } else {
                                                    dispatch(
                                                        setHotspotActions({
                                                            hotspotId: currentInterface.id,
                                                            action: "UI Multi choice",
                                                            date: new Date(),
                                                            data: element.value,
                                                        }),
                                                    );
                                                }
                                            }
                                        },
                                    );

                                if (
                                    currentInterface.contents.preClickContent.userInterface!.length ===
                                    userInterfaceIndex + 1
                                ) {
                                    if (videoCurrentState === "Question") {
                                        dispatch(
                                            setHotspotActions({
                                                hotspotId: currentInterface.id,
                                                action: "User Interface Completed",
                                                date: new Date(),
                                                questionId: currentQuestion.id,
                                            }),
                                        );
                                    } else {
                                        dispatch(
                                            setHotspotActions({
                                                hotspotId: currentInterface.id,
                                                action: "User Interface Completed",
                                                date: new Date(),
                                            }),
                                        );
                                    }
                                    setInterfaceCompleted(currentInterface.id);
                                    setCurrentInterface(undefined);

                                    if (currentInterface.actions?.appFeedback?.audio) {
                                        dispatch(setCurrentAudio(currentInterface.actions?.appFeedback?.audio));
                                    }
                                    if (currentInterface.actions?.appFeedback?.video) {
                                        dispatch(setCurrentFeedbackVideo(currentInterface.actions?.appFeedback?.video));
                                    }
                                    if (currentInterface.actions?.appFeedback?.image) {
                                        dispatch(setCurrentFeedbackImage(currentInterface.actions?.appFeedback?.image));
                                    }
                                    if (currentInterface.actions?.appFeedback?.input) {
                                        dispatch(
                                            setCurrentFeedbackInput({
                                                hotspotId: currentInterface.id,
                                                question: currentInterface.actions?.appFeedback?.input,
                                            }),
                                        );
                                    }
                                    if (handleProceedVideo && currentInterface.actions?.appFeedback?.proceed) {
                                        handleProceedVideo(currentInterface.actions?.appFeedback?.delay);
                                    }
                                    if (currentInterface.actions?.navigate) {
                                        if (currentInterface.actions?.navigate === "/") {
                                            navigate("/");
                                        } else {
                                            window.open(currentInterface.actions?.navigate);
                                        }
                                    }

                                    if (handleChangeScene && currentInterface.actions?.movement?.sceneId) {
                                        if (currentInterface.actions?.movement?.sceneId !== "Scene Undefined") {
                                            handleChangeScene(
                                                currentInterface.actions?.movement?.sceneId,
                                                currentInterface.actions?.movement?.delay,
                                            );
                                        }
                                    }
                                } else {
                                    setUserInterfaceIndex(userInterfaceIndex + 1);
                                }
                            }
                        }}
                    >
                        {currentInterface.contents.preClickContent.userInterface!.length === userInterfaceIndex + 1
                            ? "Submit"
                            : "Next"}
                    </VTButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
