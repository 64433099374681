import { useState } from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import { IScene } from "../../models/scenario.models";
import { ImageList, ImageListItem, Tooltip } from "@mui/material";
import { textCutOffAddElipsis } from "../../helpers/UIHelpers";
import { Params, useNavigate, useParams } from "react-router-dom";
import { EMedia } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { VTButton } from "@virtus-tech-repository/virtus-tech-repository";
import { ArrowBackIosRounded, ArrowForwardIosRounded, CloseRounded } from "@mui/icons-material";

interface IProps {
    stepperArray: IScene[];
    mediaType?: EMedia;
}

export default function VTStepper({ stepperArray, mediaType }: IProps) {
    const navigate = useNavigate();
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const [stepperOpen, setStepperOpen] = useState<boolean>(true);

    return (
        <>
            <VTButton
                sx={{
                    backgroundColor: "rgba(10,10,10,0.6)",
                    borderRadius: "0px 15px 15px 0px",
                    ":hover": {
                        backgroundColor: "rgba(10,10,10,0.8)",
                    },
                    position: "absolute",
                    bottom: "100px",
                    zIndex: 5,
                    height: "50px",
                    width: "15px",
                    left: 0,

                    transform: stepperOpen ? "translateX(-100px)" : 0,
                    transition: "1s",
                }}
                onClick={() => setStepperOpen(true)}
            >
                <ArrowForwardIosRounded />
            </VTButton>

            <Box
                component={"div"}
                sx={{
                    left: "50%",
                    transform: stepperOpen ? "translateX(-50%)" : "translateX(-200%)",
                    transition: "1s",
                    position: "absolute",
                    maxWidth: "60vw",
                    bottom: mediaType === EMedia.VIDEO || mediaType === EMedia.VIDEO_360 ? "90px" : "0px",
                }}
            >
                <ImageList
                    sx={{
                        overflowX: "auto",
                        width: "60vw",
                        marginRight: "-0.2px",
                    }}
                    rowHeight={150}
                >
                    <VTButton
                        sx={{
                            backgroundColor: "rgba(10,10,10,0.6)",
                            borderRadius: "15px 15px 0px 0px",
                            ":hover": {
                                backgroundColor: "rgba(10,10,10,0.8)",
                            },
                            position: "absolute",
                            bottom: "166px",
                            zIndex: 5,
                            height: "30px",
                            width: "30px",
                            left: "calc(50% - 30px)",
                        }}
                        onClick={() => {
                            setStepperOpen(false);
                        }}
                    >
                        <CloseRounded />
                    </VTButton>

                    <VTButton
                        sx={{
                            backgroundColor: "rgba(10,10,10,0.6)",
                            borderRadius: "15px 0px 0px 15px",
                            ":hover": {
                                backgroundColor: "rgba(10,10,10,0.8)",
                            },
                            position: "absolute",
                            left: -64,
                            zIndex: 5,
                            height: "150px",
                            width: "50px",
                        }}
                        disabled={stepperArray.findIndex((scene: IScene) => scene.id === sceneId) === 0}
                        onClick={() => {
                            navigate(
                                `/viewer/${scenarioId}/${
                                    stepperArray[stepperArray.findIndex((scene: IScene) => scene.id === sceneId) - 1].id
                                }`,
                            );
                        }}
                    >
                        <ArrowBackIosRounded />
                    </VTButton>
                    <ImageListItem
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "rgba(10,10,10,0.6)",
                            marginRight: "-5px",
                            alignItems: "center",
                        }}
                    >
                        {stepperArray.map((scene: IScene) => {
                            return (
                                <Box
                                    component={"div"}
                                    sx={{
                                        width: "150px",
                                        height: "100px",
                                        marginInline: "15px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        navigate(`/viewer/${scenarioId}/${scene.id}`);
                                    }}
                                >
                                    <img
                                        src={
                                            scene?.mediaType === "three_sixty_video"
                                                ? scene.src.split(".mp4")[0] + "_preview.png"
                                                : `${scene?.src}/f.jpg`
                                        }
                                        style={{
                                            borderRadius: "15px",
                                            objectFit: "cover",
                                            boxShadow: sceneId === scene.id ? "0 0 25px #2ad5c7" : "none",
                                        }}
                                        alt="preview"
                                        width="100%"
                                        height="80px"
                                    />
                                    <Tooltip title={scene.name} disableHoverListener={scene.name.length <= 16}>
                                        <Typography align={"center"}>
                                            {scene.name.length <= 16
                                                ? scene.name
                                                : textCutOffAddElipsis(scene.name, 16)}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                            );
                        })}
                    </ImageListItem>
                    <VTButton
                        sx={{
                            backgroundColor: "rgba(10,10,10,0.6)",
                            borderRadius: "0px 15px 15px 0px",
                            ":hover": {
                                backgroundColor: "rgba(10,10,10,0.8)",
                            },
                            position: "absolute",
                            right: -64,
                            height: "150px",
                            width: "50px",
                        }}
                        disabled={
                            stepperArray.findIndex((scene: IScene) => scene.id === sceneId) === stepperArray.length - 1
                        }
                        onClick={() => {
                            navigate(
                                `/viewer/${scenarioId}/${
                                    stepperArray[stepperArray.findIndex((scene: IScene) => scene.id === sceneId) + 1].id
                                }`,
                            );
                        }}
                    >
                        <ArrowForwardIosRounded />
                    </VTButton>
                </ImageList>
            </Box>
        </>
    );
}
