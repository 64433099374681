import { Vector3 } from "three";

export enum EHotspotType {
    MOVEMENT = "movement",
    INFORMATION = "information",
    ANSWER = "answer",
    QUESTION = "question",
    INTERFACE = "interface",
}

export interface IHotspot {
    id: string;
    location: Vector3;
    type: EHotspotType;
    styles?: { preClickStyles?: IStyle; postClickStyles?: IStyle };
    contents: { preClickContent: IContent; postClickContent?: IContent };
    actions?: IActions;
    timesVisible?: { startTime: number; finishTime: number }[];
}

export interface IStyle {
    colours?: IColours;
    hidden?: boolean;
    closeControls?: boolean;
    hover?: IHover;
    lookAt?: Vector3;
    scale?: number;
}

export interface IColours {
    background?: { r: number; g: number; b: number; a: number };
    border?: { r: number; g: number; b: number; a: number };
    text?: { r: number; g: number; b: number; a: number };
}

export interface IHover {
    scale?: number;
    colours?: IColours;
    audio?: string;
}

export interface IContent {
    question?: IQuestion;
    contentData?: string;
    icon?: string;
    title?: string;
    userInterface?: IUserInterface[];
}

export interface IQuestion {
    time: number;
    countdown?: number;
    answers: IHotspot[];
    audio?: string;
}
export interface IUserInterface {
    sectionTitle?: string;
    sectionContent?: { id: string; field: string; value: string }[];
    sectionDescription?: string;
    sectionFeedback?: string;
}

export interface IActions {
    movement?: IMovement;
    appFeedback?: IAppFeedback;
    autoClose?: number;
    navigate?: string;
    closeable?: boolean;
}

export interface IMovement {
    sceneId?: string;
    delay?: number;
    time?: number;
    startDirection?: Vector3;
}

export interface IAppFeedback {
    image?: string;
    video?: string;
    audio?: string;
    input?: string;
    proceed?: boolean;
    delay?: number;
}
