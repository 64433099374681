//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Card, CardContent, CardMedia, Grid, Paper, Skeleton, Tooltip } from "@mui/material";
import { current } from "@reduxjs/toolkit";
import { VTButton, VTTextField, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import { useEffect, useState } from "react";
import { AddRounded, CancelRounded, SaveRounded, EditRounded, TimerRounded } from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// Custom components imports (and hooks and helpers)

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    title?: string;
    countdown?: number;
    questionId: string;
    handleEditQuestionTitle: (questionId: string, newTitle: string) => void;
    handleEditQuestionTimer: (questionId: string, newTimer: number) => void;
    marginTop: string;
    handleProceedVideo: any;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorContainer({
    title,
    countdown,
    questionId,
    handleEditQuestionTitle,
    marginTop,
    handleEditQuestionTimer,
    handleProceedVideo,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    const [currentTitle, setCurrentTitle] = useState<string>("");

    const [currentTimer, setCurrentTimer] = useState<number>(0);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (title) {
            setCurrentTitle(title);
        } else {
            setCurrentTitle("");
        }
    }, [title]);

    useEffect(() => {
        if (countdown) {
            setCurrentTimer(countdown);
        } else {
            setCurrentTimer(0);
        }
    }, [countdown]);

    useEffect(() => {
        if (currentTimer > 1) {
            const timer = setTimeout(() => {
                setCurrentTimer(currentTimer - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else if (currentTimer <= 1 && currentTimer !== 0) {
            const timer = setTimeout(() => {
                setCurrentTimer(currentTimer - 1);
                handleProceedVideo(2);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [currentTimer]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Render skeleton cards
    return (
        <>
            {currentTitle !== "" && (
                <Paper
                    sx={{
                        position: "absolute",
                        top: marginTop,
                        right: currentTimer !== 0 ? "40%" : "30%",
                        left: currentTimer !== 0 ? "20%" : "30%",
                        zIndex: 1000,
                        backgroundColor: "rgba(0,0,0,0.6)",
                        padding: "15px",
                    }}
                >
                    <Grid item container xs={12} justifyContent={"center"}>
                        <Grid item>
                            <Grid item>
                                <VTTypography align="center">{currentTitle}</VTTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            )}
            {currentTimer !== 0 && (
                <Paper
                    sx={{
                        position: "absolute",
                        top: marginTop,
                        right: currentTitle !== "" ? "25%" : "45%",
                        left: currentTitle !== "" ? "62%" : "45%",
                        zIndex: 1000,
                        backgroundColor: "rgba(0,0,0,0.6)",
                        padding: "15px",
                    }}
                >
                    <Grid item container xs={12} justifyContent={"center"}>
                        <Grid item>
                            <Grid item>
                                <VTTypography>{currentTimer}</VTTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </>
    );
}
