import Login from "../views/pages/LoginPage";
import { useAppSelector } from "../store/hooks";
import { Params, useParams } from "react-router-dom";

interface IProps {
    children: React.ReactNode;
}

export default function ProtectedRoute({ children }: IProps) {
    const { id } = useAppSelector((state) => state.userReducer);
    const { scenarioId }: Readonly<Params<string>> = useParams();

    if (scenarioId === "72942f4b-e572-4f86-86fa-29422c547c55") {
        return <>{children}</>;
    }

    return <>{id !== "" ? children : <Login />} </>;
}
